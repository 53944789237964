var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aRow',{staticClass:"mb-20 header",staticStyle:{"padding-bottom":"0"}},[_c('aCol',{attrs:{"span":10}},[_c('h2',{staticClass:"upper mt-0"},[_c('a-icon',{attrs:{"type":"bank"}}),_vm._v(" CONTA BANCÁRIA ID "+_vm._s(_vm.bankAccountId)+"\n      ")],1)])],1),_vm._v(" "),_c('aTabs',{staticClass:"miles-cards-tabs",attrs:{"type":"card","defaultActiveKey":_vm.activeBankAccountTab},on:{"change":_vm.onChangeTabs}},[_c('a-tab-pane',{key:"bank",attrs:{"tab":"Dados da Conta","force-render":""}},[_c('div',{staticClass:"travel-drawer mt-10"},[(_vm.bankDetailsIsLoaded)?_c('EditCompanyBankAccounts',{attrs:{"type":"update","bankAccount":_vm.bankAccount,"bankDetailsIsLoaded":_vm.bankDetailsIsLoaded,"fieldSizes":{
            bank_id: 8,
            company_name: 8,
            company_id: 8,
            cnpj: 8,
            agency: 6,
            account: 6,
            operation: 4,
            status: 6,
            allow_payments: 24,
            logo: 24,
            bank_manager_name: 6,
            bank_manager_last_name: 6,
            bank_manager_main_phone: 6,
            bank_manager_phone: 6,
            bank_manager_email: 6,
          }},on:{"listcompanyBankAccountsAfterCreate":_vm.listcompanyBankAccountsAfterCreate}}):_vm._e()],1)]),_vm._v(" "),(_vm.bankAccount.id)?_c('a-tab-pane',{key:"notes",attrs:{"force-render":""}},[_c('template',{slot:"tab"},[_vm._v("\n        Anotações\n        "),_c('a-tag',{staticClass:"ml-5",attrs:{"color":"blue","id":`count-notes-${_vm.bankAccount.id}`}},[_vm._v("\n          0\n        ")])],1),_vm._v(" "),_c('Notes',{attrs:{"module":"company-bank-accounts","moduleId":_vm.bankAccount.id}})],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }