<template>
  <aForm :form="form" @submit="handleSubmit">
    <aRow :gutter="20">
      <aCol :span="fieldSizes.bank_id">
        <aFormItem class="travel-input-outer">
          <label for :class="form.getFieldValue('bank_id') ? 'filled' : ''"
            >Banco</label
          >
          <a-select
            class="travel-input"
            show-search
            placeholder="Banco"
            optionFilterProp="txt"
            @change="theSelectedBank"
            v-decorator="[
              'bank_id',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of banks"
              :key="index"
              :value="item.id"
              :txt="item.nome"
            >
              {{ item.numero }} - {{ item.nome }}
            </a-select-option>
          </a-select>
        </aFormItem>
      </aCol>

      <aCol :span="fieldSizes.company_name">
        <aFormItem class="travel-input-outer">
          <label
            for
            :class="form.getFieldValue('company_name') ? 'filled' : ''"
          >
            Nome da Conta
          </label>
          <a-input
            class="travel-input"
            placeholder="Nome da Conta "
            v-decorator="[
              'company_name',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </a-input>
        </aFormItem>
      </aCol>

      <aCol :span="fieldSizes.company_id">
        <aFormItem class="travel-input-outer">
          <label for :class="form.getFieldValue('company_id') ? 'filled' : ''"
            >Empresa</label
          >
          <a-select
            class="travel-input"
            placeholder="Selecione uma empresa"
            optionFilterProp="txt"
            @change="getCompanyCNPJ"
            v-decorator="[
              `company_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of companiesList"
              :key="index"
              :value="item.id"
              :txt="item.company_name"
            >
              {{ item.id }} -
              {{ item.company_name }}
            </a-select-option>
          </a-select>
        </aFormItem>
      </aCol>

      <aCol :span="fieldSizes.cnpj">
        <aFormItem class="travel-input-outer">
          <label for :class="form.getFieldValue('cnpj') ? 'filled' : ''"
            >CNPJ da empresa</label
          >
          <a-input
            class="travel-input"
            placeholder="CNPJ"
            v-mask="'##.###.###/####-##'"
            v-decorator="[
              'cnpj',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </a-input>
        </aFormItem>
      </aCol>

      <aCol :span="fieldSizes.agency">
        <aFormItem class="travel-input-outer">
          <label for :class="form.getFieldValue('agency') ? 'filled' : ''"
            >Agência</label
          >
          <a-input
            class="travel-input"
            placeholder="Agência"
            v-decorator="[
              'agency',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </a-input>
        </aFormItem>
      </aCol>

      <aCol :span="fieldSizes.account">
        <aFormItem class="travel-input-outer">
          <label for :class="form.getFieldValue('account') ? 'filled' : ''"
            >Conta</label
          >
          <a-input
            class="travel-input"
            placeholder="Conta"
            v-decorator="[
              'account',
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </a-input>
        </aFormItem>
      </aCol>

      <aCol :span="fieldSizes.operation">
        <aFormItem class="travel-input-outer">
          <label for :class="form.getFieldValue('operation') ? 'filled' : ''"
            >Operação</label
          >
          <a-input
            class="travel-input"
            placeholder="Operação"
            v-decorator="[
              'operation',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </a-input>
        </aFormItem>
      </aCol>

      <aCol :span="fieldSizes.status">
        <aFormItem class="travel-input-outer">
          <label for :class="form.getFieldValue(`status`) ? 'filled' : ''">
            Status
          </label>

          <a-select
            class="travel-input"
            placeholder="Status"
            optionFilterProp="txt"
            v-decorator="[
              `status`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of status"
              :key="index"
              :value="item.label"
              :txt="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </aFormItem>
      </aCol>

      <aCol v-if="type !== 'create'" :span="fieldSizes.logo">
        <label
          for
          style="
            font-size: 10px !important;
            font-weight: 500;
            color: #ccc;
            transition: 0.3s;
            line-height: 1;
            display: block;
            width: 100%;
            margin-bottom: 5px;
            margin-top: -5px;
          "
        >
          Logo do banco</label
        >
        <div class="pb-20">
          <template v-if="!bankLogo">
            <aUpload
              name="file"
              :multiple="false"
              :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=company-bank-accounts&id=${this.bankAccount.id}`"
              :remove="removeUploadedFile"
              :headers="{
                authorization: `Bearer ${$store.state.token}`,
              }"
              @change="uploadedFile"
              v-model="bankLogo"
            >
              <aButton type="dashed" class="mt-5" size="large" shape="circle">
                <aIcon type="file" />
              </aButton>
            </aUpload>
          </template>
          <template v-if="bankLogo">
            <aAvatar :src="bankLogo" :size="60"></aAvatar>
            <aIcon
              @click="bankLogo = ''"
              class="red ml-10"
              type="close-circle"
            />
          </template>
        </div>
      </aCol>

      <aCol :span="fieldSizes.allow_payments">
        <a-checkbox
          class="mb-20"
          v-model="allowPayments"
          style="font-size: 13px; font-weight: 500; position: relative"
          @change="setAllowPaymentsValue"
        >
          Usar conta para recebimentos nos contratos.
        </a-checkbox>

        <aFormItem style="display: none">
          <a-input v-decorator="['allow_payments']" />
        </aFormItem>
      </aCol>

      <a-divider class="mt-20" orientation="left"> Dados do Gerente </a-divider>

      <aCol :span="fieldSizes.bank_manager_name">
        <aFormItem class="travel-input-outer">
          <label
            for
            :class="form.getFieldValue('bank_manager_name') ? 'filled' : ''"
            >Nome
          </label>
          <a-input
            class="travel-input"
            placeholder="Nome"
            v-decorator="[
              'bank_manager_name',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </a-input>
        </aFormItem>
      </aCol>

      <aCol :span="fieldSizes.bank_manager_last_name">
        <aFormItem class="travel-input-outer">
          <label
            for
            :class="
              form.getFieldValue('bank_manager_last_name') ? 'filled' : ''
            "
            >Sobrenome</label
          >
          <a-input
            class="travel-input"
            placeholder="Sobrenome"
            v-decorator="[
              'bank_manager_last_name',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </a-input>
        </aFormItem>
      </aCol>

      <aCol :span="fieldSizes.bank_manager_main_phone">
        <aFormItem class="travel-input-outer">
          <label
            for
            :class="
              form.getFieldValue('bank_manager_main_phone') ? 'filled' : ''
            "
            >Telefone 1</label
          >
          <a-input
            class="travel-input"
            placeholder="Telefone 1"
            v-mask="['(##) # ####-####', '(##) ####-####']"
            v-decorator="[
              'bank_manager_main_phone',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </a-input>
        </aFormItem>
      </aCol>

      <aCol :span="fieldSizes.bank_manager_phone">
        <aFormItem class="travel-input-outer">
          <label
            for
            :class="form.getFieldValue('bank_manager_phone') ? 'filled' : ''"
            >Telefone 2</label
          >
          <a-input
            class="travel-input"
            placeholder="Telefone 2"
            v-mask="['(##) # ####-####', '(##) ####-####']"
            v-decorator="[
              'bank_manager_phone',
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </a-input>
        </aFormItem>
      </aCol>

      <aCol :span="fieldSizes.bank_manager_email">
        <aFormItem class="travel-input-outer">
          <label
            for
            :class="form.getFieldValue('bank_manager_email') ? 'filled' : ''"
            >E-mail</label
          >
          <a-input
            class="travel-input"
            placeholder="E-mail"
            v-decorator="[
              'bank_manager_email',
              {
                rules: [
                  {
                    type: 'email',
                    message: 'Escreva um e-mail válido',
                  },
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </a-input>
        </aFormItem>
      </aCol>

      <aFormItem style="display: none">
        <a-input v-decorator="['name']" />
      </aFormItem>

      <aFormItem style="display: none">
        <a-input v-decorator="['number']" />
      </aFormItem>

      <aCol class="a-center mt-20" :span="24">
        <aButton
          size="large"
          type="primary"
          html-type="submit"
          :loading="loading"
        >
          {{
            type == "create"
              ? "Cadastrar conta bancária"
              : "Atualizar conta bancária"
          }}
        </aButton>
      </aCol>
    </aRow>
  </aForm>
</template>

<script>
import banks from "@/json/banks.json";

export default {
  name: "EditCompanyBankAccounts",
  props: {
    bankAccount: Object,
    type: String,
    fieldSizes: Object,
    bankDetailsIsLoaded: Boolean,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      usersList: [],

      companiesList: [],
      bankLogo: "",
      banks,
      theBank: {},
      status: [
        {
          label: "Ativo",
          value: "Ativo",
        },
        {
          label: "Desativado",
          value: "Desativado",
        },
      ],
      allowPayments: false,
      loading: false,
    };
  },
  beforeMount() {
    this.$http.get(`/company/list?page=1&per_page=100`).then(({ data }) => {
      this.companiesList = data.data;
    });
  },
  mounted() {
    setTimeout(() => {
      if (this.type == "update" && this.bankDetailsIsLoaded) {
        this.bankLogo = this.bankAccount.raw.logo;
        this.form.setFieldsValue(this.bankAccount.raw);
        this.allowPayments =
          this.bankAccount.raw.allow_payments == "1" ? true : false;
      }
    }, 100);

    this.$http.get("/user/list?page=1&per_page=100").then(({ data }) => {
      this.usersList = data.data;
    });
  },
  methods: {
    uploadedFile(response) {
      if (response.file.response.url != undefined)
        this.bankLogo = response.file.response.url;
    },
    getCompanyCNPJ(val) {
      const result = this.companiesList.filter((item) => item.id == val);

      this.form.setFieldsValue({
        cnpj: result[0].cnpj,
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          if (this.theBank[0] != undefined) {
            values = {
              ...values,
              name: this.theBank[0].nome,
              number: this.theBank[0].numero,
            };
          }

          this.loading = true;

          if (this.type === "create") {
            values.user_id = this.$store.state.userData.id;

            this.$http
              .post("/company-bank-accounts/create", values)
              .then(({ data }) => {
                data;
                this.$message.success(data.message);
                this.loading = false;
                this.$emit("listcompanyBankAccountsAfterCreate", true);
              })
              .catch(({ response }) => {
                response;
                this.$message.error("Não foi possível criar a conta.");
                this.loading = false;
              });
          }

          if (this.type === "update") {
            values.id = this.bankAccount.id;
            values.user_id = this.bankAccount.user.id;
            values.logo = this.bankLogo;

            this.$http
              .post("/company-bank-accounts/update", values)
              .then(({ data }) => {
                data;
                this.$message.success(data.message);
                this.$emit("listcompanyBankAccountsAfterCreate", true);
                this.loading = false;
              })
              .catch(({ response }) => {
                response;
                this.$message.error(
                  "Não foi possível atualizar a conta bancária."
                );
                this.loading = false;
              });
          }
        }
      });
    },
    setAllowPaymentsValue() {
      setTimeout(() => {
        this.form.setFieldsValue({
          allow_payments: this.allowPayments,
        });
      }, 100);
    },
    theSelectedBank(bank) {
      let theBank = this.banks.filter((item) => {
        return item.id == bank;
      });

      this.theBank = theBank;
    },
  },
};
</script>
