<template>
  <div>
    <aRow class="mb-20 header" style="padding-bottom: 0">
      <aCol :span="10">
        <h2 class="upper mt-0">
          <a-icon type="bank" /> CONTA BANCÁRIA ID {{ bankAccountId }}
        </h2>
      </aCol>
    </aRow>

    <aTabs
      class="miles-cards-tabs"
      type="card"
      :defaultActiveKey="activeBankAccountTab"
      @change="onChangeTabs"
    >
      <a-tab-pane key="bank" tab="Dados da Conta" force-render>
        <div class="travel-drawer mt-10">
          <EditCompanyBankAccounts
            v-if="bankDetailsIsLoaded"
            type="update"
            :bankAccount="bankAccount"
            :bankDetailsIsLoaded="bankDetailsIsLoaded"
            @listcompanyBankAccountsAfterCreate="
              listcompanyBankAccountsAfterCreate
            "
            :fieldSizes="{
              bank_id: 8,
              company_name: 8,
              company_id: 8,
              cnpj: 8,
              agency: 6,
              account: 6,
              operation: 4,
              status: 6,
              allow_payments: 24,
              logo: 24,
              bank_manager_name: 6,
              bank_manager_last_name: 6,
              bank_manager_main_phone: 6,
              bank_manager_phone: 6,
              bank_manager_email: 6,
            }"
          />
        </div>
      </a-tab-pane>

      <a-tab-pane v-if="bankAccount.id" key="notes" force-render>
        <template slot="tab">
          Anotações
          <a-tag
            class="ml-5"
            color="blue"
            :id="`count-notes-${bankAccount.id}`"
          >
            0
          </a-tag>
        </template>
        <Notes module="company-bank-accounts" :moduleId="bankAccount.id" />
      </a-tab-pane>
    </aTabs>
  </div>
</template>

<script>
import companyCreditCardMixins from "@/mixins/company-credit-cards/companyCreditCardMixins.js";
import companyCCardTransactionsMixins from "@/mixins/company-credit-cards-transactions/companyCCardTransactionsMixins.js";

import formatThings from "@/mixins/general/formatThings";
import Notes from "../../general/Notes.vue";
import EditCompanyBankAccounts from "../forms/EditCompanyBankAccounts.vue";

export default {
  name: "CompanyBankAccountsModal",
  props: {
    bankAccountId: Number,
  },
  components: { Notes, EditCompanyBankAccounts },
  mixins: [
    formatThings,
    companyCreditCardMixins,
    companyCCardTransactionsMixins,
  ],
  data() {
    return {
      bankAccount: {},
      activeBankAccountTab: "bank",
      bankDetailsIsLoaded: false,
    };
  },
  mounted() {
    this.getCompanyBankAccount();
    this.$http.get("/user/read").then(({ data }) => {
      this.companyCCardTransactions.filters.users.list = data;
    });
  },
  methods: {
    listcompanyBankAccountsAfterCreate() {
      this.$emit("listcompanyBankAccountsAfterCreate", true);
    },
    getCompanyBankAccount() {
      this.$http
        .post(`/company-bank-accounts/details?id=${this.bankAccountId}`)
        .then(({ data }) => {
          this.bankAccount = data;
          this.bankDetailsIsLoaded = true;
        });
    },
    onChangeTabs(tab) {
      this.activeBankAccountTab = tab;
    },
    cancelVoucherTransaction(transaction) {
      transaction.raw.is_canceled = 1;
      transaction.raw.status = "canceled";
      transaction.raw.action = "cancel-voucher-emission";
      transaction.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/voucher-transactions/update", transaction.raw)
        .then(({ data }) => {
          this.getCompanyBankAccount();
          this.getCompanyBankAccountTransactions();
          this.$message.success(data.message);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    confirmCreditCardEmission() {
      if (this.creditCardEmission.value) {
        if (
          parseFloat(
            this.convertNumberToDatabase(this.creditCardEmission.value)
          ) <= parseFloat(this.creditCardavailable_value)
        ) {
          this.creditCardEmission.loading = true;
          this.$http
            .post("/company-credit-card-transactions/create", {
              contract_id: 0,
              customer_id: 0,
              is_canceled: 0,
              user_id: this.$store.state.userData.id,
              voucher_code: this.creditCardvoucher_code,
              currency: this.creditCardcurrency,
              value: this.convertNumberToDatabase(
                this.creditCardEmission.value
              ),
              operation: "subtract",
              extra_info: "",
              voucher_id: this.bankAccountId,
              voyage_id: this.creditCardEmission.voyageId,
            })
            .then(({ data }) => {
              this.getCompanyBankAccount();
              this.openManualEmission = false;
              this.creditCardEmission.loading = false;
              this.creditCardEmission.value = undefined;
              this.creditCardEmission.voyageId = undefined;
              this.$message.success(data.message);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.creditCardEmission.value = undefined;
              this.creditCardEmission.voyageId = undefined;
              this.creditCardEmission.loading = false;
            });
        } else {
          this.$message.warning(
            "O valor a ser retirado não pode ser maior do que o disponível."
          );
        }
      } else {
        this.$message.warning("Insira o valor a ser retirado.");
      }
    },
    formatOperation(operation) {
      if (operation == "subtract")
        operation = `<font class="tag return">EMISSÃO</font>`;

      if (operation == "canceled")
        operation = `<font class="tag subtract">CANCELADO</font>`;

      return operation;
    },
  },
};
</script>
