var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aForm',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":_vm.fieldSizes.bank_id}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue('bank_id') ? 'filled' : '',attrs:{"for":""}},[_vm._v("Banco")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bank_id',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'bank_id',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"show-search":"","placeholder":"Banco","optionFilterProp":"txt"},on:{"change":_vm.theSelectedBank}},_vm._l((_vm.banks),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.nome}},[_vm._v("\n            "+_vm._s(item.numero)+" - "+_vm._s(item.nome)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.company_name}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue('company_name') ? 'filled' : '',attrs:{"for":""}},[_vm._v("\n          Nome da Conta\n        ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company_name',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'company_name',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Nome da Conta "}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.company_id}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue('company_id') ? 'filled' : '',attrs:{"for":""}},[_vm._v("Empresa")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `company_id`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `company_id`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione uma empresa","optionFilterProp":"txt","show-search":""},on:{"change":_vm.getCompanyCNPJ}},_vm._l((_vm.companiesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.company_name}},[_vm._v("\n            "+_vm._s(item.id)+" -\n            "+_vm._s(item.company_name)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.cnpj}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue('cnpj') ? 'filled' : '',attrs:{"for":""}},[_vm._v("CNPJ da empresa")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"},{name:"decorator",rawName:"v-decorator",value:([
            'cnpj',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'cnpj',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"CNPJ"}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.agency}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue('agency') ? 'filled' : '',attrs:{"for":""}},[_vm._v("Agência")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'agency',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'agency',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Agência"}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.account}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue('account') ? 'filled' : '',attrs:{"for":""}},[_vm._v("Conta")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'account',
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'account',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Conta"}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.operation}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue('operation') ? 'filled' : '',attrs:{"for":""}},[_vm._v("Operação")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'operation',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'operation',\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Operação"}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.status}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`status`) ? 'filled' : '',attrs:{"for":""}},[_vm._v("\n          Status\n        ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `status`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `status`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Status","optionFilterProp":"txt","show-search":""}},_vm._l((_vm.status),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.label,"txt":item.label}},[_vm._v("\n            "+_vm._s(item.label)+"\n          ")])}),1)],1)],1),_vm._v(" "),(_vm.type !== 'create')?_c('aCol',{attrs:{"span":_vm.fieldSizes.logo}},[_c('label',{staticStyle:{"font-size":"10px !important","font-weight":"500","color":"#ccc","transition":"0.3s","line-height":"1","display":"block","width":"100%","margin-bottom":"5px","margin-top":"-5px"},attrs:{"for":""}},[_vm._v("\n        Logo do banco")]),_vm._v(" "),_c('div',{staticClass:"pb-20"},[(!_vm.bankLogo)?[_c('aUpload',{attrs:{"name":"file","multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=company-bank-accounts&id=${this.bankAccount.id}`,"remove":_vm.removeUploadedFile,"headers":{
              authorization: `Bearer ${_vm.$store.state.token}`,
            }},on:{"change":_vm.uploadedFile},model:{value:(_vm.bankLogo),callback:function ($$v) {_vm.bankLogo=$$v},expression:"bankLogo"}},[_c('aButton',{staticClass:"mt-5",attrs:{"type":"dashed","size":"large","shape":"circle"}},[_c('aIcon',{attrs:{"type":"file"}})],1)],1)]:_vm._e(),_vm._v(" "),(_vm.bankLogo)?[_c('aAvatar',{attrs:{"src":_vm.bankLogo,"size":60}}),_vm._v(" "),_c('aIcon',{staticClass:"red ml-10",attrs:{"type":"close-circle"},on:{"click":function($event){_vm.bankLogo = ''}}})]:_vm._e()],2)]):_vm._e(),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.allow_payments}},[_c('a-checkbox',{staticClass:"mb-20",staticStyle:{"font-size":"13px","font-weight":"500","position":"relative"},on:{"change":_vm.setAllowPaymentsValue},model:{value:(_vm.allowPayments),callback:function ($$v) {_vm.allowPayments=$$v},expression:"allowPayments"}},[_vm._v("\n        Usar conta para recebimentos nos contratos.\n      ")]),_vm._v(" "),_c('aFormItem',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['allow_payments']),expression:"['allow_payments']"}]})],1)],1),_vm._v(" "),_c('a-divider',{staticClass:"mt-20",attrs:{"orientation":"left"}},[_vm._v(" Dados do Gerente ")]),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.bank_manager_name}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue('bank_manager_name') ? 'filled' : '',attrs:{"for":""}},[_vm._v("Nome\n        ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bank_manager_name',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'bank_manager_name',\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Nome"}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.bank_manager_last_name}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue('bank_manager_last_name') ? 'filled' : '',attrs:{"for":""}},[_vm._v("Sobrenome")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bank_manager_last_name',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'bank_manager_last_name',\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Sobrenome"}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.bank_manager_main_phone}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue('bank_manager_main_phone') ? 'filled' : '',attrs:{"for":""}},[_vm._v("Telefone 1")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) # ####-####', '(##) ####-####']),expression:"['(##) # ####-####', '(##) ####-####']"},{name:"decorator",rawName:"v-decorator",value:([
            'bank_manager_main_phone',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'bank_manager_main_phone',\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Telefone 1"}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.bank_manager_phone}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue('bank_manager_phone') ? 'filled' : '',attrs:{"for":""}},[_vm._v("Telefone 2")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) # ####-####', '(##) ####-####']),expression:"['(##) # ####-####', '(##) ####-####']"},{name:"decorator",rawName:"v-decorator",value:([
            'bank_manager_phone',
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'bank_manager_phone',\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Telefone 2"}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":_vm.fieldSizes.bank_manager_email}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue('bank_manager_email') ? 'filled' : '',attrs:{"for":""}},[_vm._v("E-mail")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bank_manager_email',
            {
              rules: [
                {
                  type: 'email',
                  message: 'Escreva um e-mail válido',
                },
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            'bank_manager_email',\n            {\n              rules: [\n                {\n                  type: 'email',\n                  message: 'Escreva um e-mail válido',\n                },\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"E-mail"}},[_c('aIcon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aFormItem',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name']),expression:"['name']"}]})],1),_vm._v(" "),_c('aFormItem',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['number']),expression:"['number']"}]})],1),_vm._v(" "),_c('aCol',{staticClass:"a-center mt-20",attrs:{"span":24}},[_c('aButton',{attrs:{"size":"large","type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("\n        "+_vm._s(_vm.type == "create"
            ? "Cadastrar conta bancária"
            : "Atualizar conta bancária")+"\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }